import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

// Helpers
import showMktoForm from "../../../helpers/showMktoForm";
import Button from "../../../components/custom-widgets/button";

const Payables = () => {
  const title = "Commercial Payables";
  const description =
    "Need commercial payables? WaFd Bank offers a full suite of services for managing your payables. Schedule supplier payments, manage payroll and more.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/payables/hero-payables-12282022-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/payables/hero-payables-12282022-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/payables/hero-payables-12282022-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/payables/hero-payables-12282022-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/payables/hero-payables-12282022-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/payables/hero-payables-12282022-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/payables/hero-payables-12282022-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      benefitsOfPayables: file(
        relativePath: {
          eq: "commercial-banking/treasury-management-services/payables/thumbnail-benefits-of-wafd-payables-031124.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 600, transformOptions: { cropFocus: CENTER }, layout: CONSTRAINED)
        }
      }
      payables: file(
        relativePath: { eq: "commercial-banking/treasury-management-services/payables/thumbnail-payables-031124.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 600, transformOptions: { cropFocus: CENTER }, layout: CONSTRAINED)
        }
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: "Payables"
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/treasury-management-services/payables"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-payables-12282022.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "payables-hero",
    chevronBgClass: "bg-info",
    ...getHeroImgVariables(imgData),
    altText: "Businessman in a wheelchair at a desk.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Payable Services Customized to Your Business"
          }
        },
        {
          id: 2,
          button: {
            id: "payables-hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/treasury-management-services",
      title: "Treasury Management"
    },
    {
      id: 3,
      active: true,
      title: "Payables"
    }
  ];

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container" id="payables-intro-section">
        <h1>Payables</h1>
        <h3>
          WaFd Bank offers a full suite of services for managing your payables. You can schedule supplier payments,
          manage payroll, and integrate with your accounting system to save time; freeing your resources to focus on the
          day to day needs of your business.
        </h3>
      </section>
      <section className="bg-light" id="process-payables-your-way-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <GatsbyImage
                image={imgData.payables.childImageSharp.gatsbyImageData}
                alt="Businesswoman in office smiling while on the phone and using a laptop."
                className="mb-3 mb-lg-0"
              />
            </div>
            <div className="col-12 col-md-6">
              <h2>Process Payables Your Way</h2>
              <h5 className="text-success mb-0">ACH</h5>
              <p>
                Need to move money quickly and securely? Send payments to vendors or pay employees using the Automated
                Clearing House.
              </p>
              <h5 className="text-success mb-0">Wires</h5>
              <p>
                Need to send a large payment or send a payment quickly? You can send domestic wires, as well as foreign
                wires in both US Dollars and most foreign currencies from your desktop.
              </p>
              <h5 className="text-success mb-0">Check Printing</h5>
              <p>
                Save time and money printing, signing and mailing checks with WaFd Bank's check printing service. Upload
                a check file and let us take care of the rest, including your positive pay issue file.
              </p>
              <h5 className="text-success mb-0">Consolidated Payables</h5>
              <p className="mb-2 mb-lg-4">
                Need to do all of the above? You can load one file including all of the payment types mentioned above as
                well as account transfers and ACH debits.
              </p>
              <Button id="contact-us-btn-1" text="Contact Us" type="button" onClick={() => showMktoForm(1067)} />
            </div>
          </div>
        </div>
      </section>
      <section className="container" id="benefits-of-wafd-payables-section">
        <div className="row">
          <div className="col-12 col-md-6 order-2 order-lg-1">
            <h2>Benefits of WaFd Bank's payables service include:</h2>
            <ul id="benefits-list">
              <li id="benefits-list-item-1">Reduced exposure to check fraud</li>
              <li id="benefits-list-item-2">Reduced costs</li>
              <li id="benefits-list-item-3">Save employees time</li>
              <li id="benefits-list-item-4">Additional security with Dual Approval</li>
              <li id="benefits-list-item-5">Receive payment alerts</li>
            </ul>
            <Button id="contact-us-btn-2" text="Contact Us" type="button" onClick={() => showMktoForm(1067)} />
          </div>
          <div className="col-12 col-md-6 order-1 order-lg-2">
            <GatsbyImage
              image={imgData.benefitsOfPayables.childImageSharp.gatsbyImageData}
              alt="Businessman relaxing at his desk with hands behind head."
              className="mb-3 mb-lg-0"
            />
          </div>
        </div>
      </section>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default Payables;
